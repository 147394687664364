import Vue from 'vue'
import VueRouter from 'vue-router'
import Welcome from '../views/Welcome.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Welcome',
    component: Welcome
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/homejahr',
    name: 'homejahr',
    component: () => import('../views/HomeJahr.vue')
  },
  {
    path: '/therapieplan',
    name: 'therapieplan',
    // route level code-splitting
    // this generates a separate chunk (About.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Therapieplan.vue')
  },
  {
    path: '/werteverlauf',
    name: 'werteverlauf',
    // route level code-splitting
    // this generates a separate chunk (About.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Werteverlauf.vue')
  },
  {
    path: '/therapieplandetail/:id',
    name: 'therapieplandetail',
    // route level code-splitting
    // this generates a separate chunk (About.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/TherapieplanDetail.vue')
  },
  {
    path: '/werteabfragetemperatur',
    name: 'werteabfragetemperatur',
    // route level code-splitting
    // this generates a separate chunk (About.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/WerteabfrageTemperatur.vue')
  },
  {
    path: '/werteabfragegewicht',
    name: 'werteabfragegewicht',
    // route level code-splitting
    // this generates a separate chunk (About.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/WerteabfrageGewicht.vue')
  },
  {
    path: '/werteabfrageblutdruck',
    name: 'werteabfrageblutdruck',
    // route level code-splitting
    // this generates a separate chunk (About.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/WerteabfrageBlutdruck.vue')
  },
  {
    path: '/werteabfrageallgemeinzustand',
    name: 'werteabfrageallgemeinzustand',
    // route level code-splitting
    // this generates a separate chunk (About.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/WerteabfrageAllgemeinzustand.vue')
  },
  {
    path: '/werteabfragesymptome',
    name: 'werteabfragesymptome',
    // route level code-splitting
    // this generates a separate chunk (About.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/WerteabfrageSymptome.vue')
  },
  {
    path: '/werteabfragewertebericht',
    name: 'werteabfragewertebericht',
    // route level code-splitting
    // this generates a separate chunk (About.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/WerteabfrageWertebericht.vue')
  },
  {
    path: '/information',
    name: 'information',
    // route level code-splitting
    // this generates a separate chunk (About.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Information.vue')
  },
  {
    path: '/informationnachdertx',
    name: 'informationnachdertx',
    // route level code-splitting
    // this generates a separate chunk (About.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/InformationNachderTX.vue')
  },
  {
    path: '/informationmedikation',
    name: 'informationmedikation',
    // route level code-splitting
    // this generates a separate chunk (About.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/InformationMedikation.vue')
  },
  {
    path: '/informationkomplikationen',
    name: 'informationkomplikationen',
    // route level code-splitting
    // this generates a separate chunk (About.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/InformationKomplikationen.vue')
  },
  {
    path: '/informationvorsorge',
    name: 'informationvorsorge',
    // route level code-splitting
    // this generates a separate chunk (About.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/InformationVorsorge.vue')
  },
  {
    path: '/informationernaehrung',
    name: 'informationernaehrung',
    // route level code-splitting
    // this generates a separate chunk (About.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/InformationErnaehrung.vue')
  },
  {
    path: '/informationwerteverstehen',
    name: 'informationwerteverstehen',
    // route level code-splitting
    // this generates a separate chunk (About.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/InformationWerteverstehen.vue')
  },
  {
    path: '/registrationusername',
    name: 'registrationusername',
    // route level code-splitting
    // this generates a separate chunk (About.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/RegistrationUsername.vue')
  }
  ,
  {
    path: '/registrationtime',
    name: 'registrationtime',
    // route level code-splitting
    // this generates a separate chunk (About.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/RegistrationTime.vue')
  }
  ,
  {
    path: '/registrationmedication',
    name: 'registrationmedication',
    // route level code-splitting
    // this generates a separate chunk (About.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/RegistrationMedication.vue')
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
