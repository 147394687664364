<template>
  
  <div id="app">
    <!-- <button @click="writeUserData()" style="background-color:#fff">createNewUser</button>
    <input type="text" v-model="userName">
    <input type="text" v-model="password">
    <button @click="userLogin()" style="background-color:#fff">login</button>
    <div>{{getUserData.email}}</div>

    <p v-if="userName == 'manuel'">hello</p>
    <div v-for="value in getUserData.medikamente" 
      :key='value.name'>
        <p>
          {{value.name + " " + value.dosierung}}
        </p>
    </div>-->

    <router-view/>
  </div>
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
// Import the functions you need from the SDKs you need
//import { initializeApp } from "firebase/app";
//import { getDatabase, ref, set } from "firebase/database";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

export default {
  name: 'App',
  data() {
    return {
      userName: '234',
      password: ''
    }
  },
  components: {
  },
  methods:{
    ...mapActions(['initializeFirebase', 'writeUserDataToDB', 'loadUserData']),
    writeUserData(){
      this.writeUserDataToDB(this.userName);
    },
    userLogin(){
      this.loadUserData({
        user: this.userName, 
        pw: this.password
      });
    }
    /*writeUserData(userId, name, email, imageUrl) {
      const db = getDatabase();
      set(ref(db, 'users/' + userId), {
        username: name,
        email: email,
        profile_picture : imageUrl
      });
    }*/
  },
  computed: {
     ...mapGetters(['getUserData']),
  },
  created(){
    console.log("test");
    this.initializeFirebase();
  },
}
</script>

<style>

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

body {
  font-family: 'Open Sans';
  background-color: #0B1010;
  height:100%;
}

.bodywhite {
  height: 100vh;
  width: 100vw;
  background-color: #ffffff;
}

.bodysecondaryblue {
  height: 100vh;
  width: 100vw;
  background-color: #181E22;
}
/*________________________________________________________________________________________*/

.backgroundcolor-white {
  background-color: white;
}

.backgroundcolor-beige {
  background-color: #F6F6F6;
}

.backgroundcolor-mainblue {
  background-color: #0B1010;
}

.backgroundcolor-secondaryblue {
  background-color: #181E22;
}

.backgroundcolor-tertiaryblue {
  background-color: #25323A;
}

.backgroundcolor-lightblue {
  background-color: #4E75FF;
}

.backgroundcolor-transparent {
  background-color: transparent;
}

/*________________________________________________________________________________________*/

.fontcolor-white {
  color: white;
}

.fontcolor-mainblue {
  color: #0B1010 !important;
}

.fontcolor-lightblue {
  color: #4E75FF !important;
}

/*________________________________________________________________________________________*/

h1 {
    font-size: 24px;
    font-weight: 700;
}

.h2 {
  font-size:20px;
  font-weight: 700;
  letter-spacing: 0.2px;
  line-height: 30px;
}

.h3 {
  font-size:18px;
  font-weight: 700;
  letter-spacing: 0.2px;
}

.h4 {
    font-size:12px;
    font-weight: 200; /* change */
}

.h5 {
    font-size: 50px;
    font-weight: 700;
}

p {
  margin-top: 0;
  margin-bottom: 0;
}

.pregular {
    font-size:16px;
    font-weight: 200;
    letter-spacing: 0.2px;
}

.psemibold {
    font-size:16px;
    font-weight: 550;
    letter-spacing: 0.2px;
}

.pbold {
    font-size:16px;
    font-weight: 700;
    letter-spacing: 0.2px;
}

.pbolduppercase {
    font-size:16px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.8px;
}

.psmallregular {
  font-size:14px;
  font-weight: 200;
  letter-spacing: 0.2px;
}

.psmallsemibold {
  font-size:14px;
  font-weight: 550;
  letter-spacing: 0.2px;
}

.psmallbold {
  font-size:14px;
  font-weight: 700;
  letter-spacing: 0.2px;
}


/*________________________________________________________________________________________*/



nav {
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 95px;
  background-color: rgba(11, 16, 16, .95);
  box-shadow: 0px -2px 14px rgba(0, 0, 0, 0.35);
  bottom: 0;
  padding-top: 15px;
  z-index: 9;
  backdrop-filter: blur(5px);
}

.center {
  position: relative;
  margin: auto;
}

.header {
  overflow: hidden;
  background-color: #0B1010;
  position: fixed;
  top: 0;
  z-index: 10;
  width: 100%;
  padding-top: 70px;
  padding-bottom: 15px;
}

.headertransparent {
  overflow: hidden;
  position: fixed;
  top: 0;
  z-index: 10;
  width: 100%;
  padding-top: 70px;
  padding-bottom: 15px;
}

.col-center {
    text-align: center;
}

.col-center-content {
    display: flex;
    justify-content: center;
}

.col-right {
    text-align: right;
}


.nopadding {
  padding: 0 !important;
}

.background-color2{
  background-color: #181E22;
}

/* Light Variante */
.header-light {
  overflow: hidden;
  background-color: #F6F6F6;
  position: fixed;
  top: 0;
  z-index: 1;
  width: 100%;
  padding-top: 70px;
  padding-bottom: 15px;
}

.bodywhite {
  background-color: white;
}

.textcolor {
  color: #0B1010;
}

.textcolor-white {
  color: white;
}

.trennlinie-light {
  border-top: 1px solid;
  border-top-color: #F6F6F6;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 20px;
}

/* Informationen */

.aufgabe {
  background-color: #25323A;
  padding-bottom: 25px;
  padding-top: 25px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  display: inline-block;
  border: none;
  z-index: 2;
  position: relative;
  width: calc(100% - 20px);
}

.aufgabelist {
  background-color: #25323A;
  padding-bottom: 1px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 10px;
  display: block;
  border: none;
  z-index: 2;
  position: relative;
}

.left {
  text-align: left;
}

/* Informationen-Medikation */


.accordion {
  width: 100%;
  color: #ffffff;
  cursor: pointer;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  text-align: left;
}

.accordionblack {
  width: 100%;
  padding: 18px 0px;
  color: #0B1010;
  cursor: pointer;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  text-align: left;
}

.cardblack {
  background-color: #0B1010;
}

.accordion .accordionzeile {
  position: relative;
  padding-right: 15px;
}

.accordion .label {
  position: relative;
  padding: 5px 0;
  padding-bottom: 10px;
  cursor: pointer;
}

.accordion .label::before {
  content: '+';
  color: white;
  position: absolute;
  top: 50%;
  right: -5px;
  font-size: 30px;
  transform: translateY(-50%);
}

.accordion .content {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: 0.5s;
}

.accordion hr {
  border: 0.5px solid rgba(255, 255, 255, 0.2);
}

/*.accordion hr {
  border: 0.5px solid rgba(0, 0, 0, 0.05);
}*/

.accordion .accordionzeile.active .content {
  height: auto;
}

.accordion .accordionzeile.active .label::before {
  content: '–';
  font-size: 30px;
}


.accordionlist {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.trennlinie {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  margin-left: 10px;
  margin-right: 10px;
}

/* Abstände */

.margintop5 {
  margin-top: 5px;
}

.margintop140 {
  margin-top: 140px;
}

.margintop170 {
  margin-top: 170px;
}

.margintop108 {
  margin-top: 108px;
}

.margintop90 {
  margin-top: 90px;
}

.margintop80 {
  margin-top: 80px;
}

.margintop70 {
  margin-top: 70px;
}

.margintop50 {
  margin-top: 50px;
}

.margintop40 {
  margin-top: 40px;
}

.margintop30 {
  margin-top: 30px;
}

.margintop25 {
  margin-top: 25px;
}

.margintop20 {
  margin-top: 20px;
}

.paddingtop15 {
  padding-top: 15px;
}

.paddingtop20 {
  padding-top: 25px;
}

.paddingtop40 {
  padding-top: 40px;
}

.paddingtop50 {
  padding-top: 50px;
}

.paddingtop60 {
  padding-top: 60px;
}

.paddingtop70 {
  padding-top: 70px;
}

.paddingtop80 {
  padding-top: 80px;
}

.paddingtop90 {
  padding-top: 90px;
}

.paddingtop108 {
  padding-top: 108px;
}

.paddingtop115 {
  padding-top: 115px;
}

.paddingtop155 {
  padding-top: 155px;
}



.marginbottom135 {
  margin-bottom: 135px;
}

.marginbottom95 {
  margin-bottom: 95px;
}

.marginbottom25 {
  margin-bottom: 25px;
}

.marginbottom20 {
  margin-bottom: 20px;
}

.paddingbottom110 {
  padding-bottom: 110px;
}

.paddingbottom5 {
  padding-bottom: 5px;
}


/* nav */

.navicon {
  opacity: 0.4;
}

.navicon:active {
  opacity: 1;
}

/* opacity */
.opacity0 {
  opacity: 0;
}

.opacity20 {
  opacity: 0.2;
}

.opacity40 {
  opacity: 0.4;
}

.opacity80 {
  opacity: 0.8;
}



/* Werteabfrage // Progressbar */

.progressbar {
  background-color: #E7E7E7;
}

.progressvalue {
  background-color: #4E75FF;
  height:7px;
}

progress[value] {
  width: 100vw;
  height: 8px;
  border-radius: 100%;
  background-color: blue !important;
}

progress[value]::-webkit-progress-bar {
  background-color: #E7E7E7;
  color: blue;
}

/* Werteabfrage // Footer */

.buttonS {
  border: none;
  padding: 10px 32px;
  width: 80%;
  text-align: center;
  display: inline-block;
  margin: 10px 2px;
  cursor: pointer;
  border-radius: 100px;
}

.buttonAutoWidth {
  border: none;
  padding: 10px 40px;
  text-align: center;
  display: inline-block;
  margin: 7px 0px;
  cursor: pointer;
  border-radius: 100px;
}

.buttonfullwidth {
  border: none;
  color: white;
  padding: 10px 32px;
  width: 100%;
  text-align: center;
  display: inline-block;
  margin: 10px 2px;
  cursor: pointer;
  background-color: #0B1010;
  border-radius: 100px;
}

.buttonfullwidth-white {
  border: none;
  padding: 10px 32px;
  width: 100%;
  text-align: center;
  display: inline-block;
  margin: 10px 2px;
  cursor: pointer;
  background-color: white;
  border-radius: 100px;
}

.buttonhoch {
  border: none;
  padding: 15px 40px;
  width: 100%;
  text-align: center;
  display: inline-block;
  margin: 5px 2px;
  cursor: pointer;
  border-radius: 100px;
}

.buttonXS {
  border: none;
  padding: 10px 0px;
  text-align: center;
  display: inline-block;
  margin: 10px 2px;
  cursor: pointer;
  border-radius: 100px;
}

.stepnavigation {
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 95px;
  bottom: 0;
  padding-top: 15px;
  z-index: 2;
}

.welcome {
  background-color: #0B1010;
    text-align: center;
    overflow: hidden;
    position: fixed;
    width: 100%;
    /* height: 205px; */
    bottom: 0;
    padding-top: 0;
    padding-bottom: 20px;
    z-index: 2;
}

/* Werteabfrage // Numberinput */



.input  {
  text-align: right;
  font-size: 65px;
  font-weight: bold;
  width: 90%;
  border: none;
}

.input:focus {
  outline: none;
}

button {
  border: none;
  background: transparent;
}

button:focus {
  outline: none;
}


/* Werteabfrage // Singleselection */

.col {
  display: block;
  float:left;
  margin: 1% 0 1% 1.6%;
}

.form .plan input, .form .payment-plan input, .form .payment-type input{
	display: none;
}

.form label{
	position: relative;
	text-align: center;
	height: 36px;
  width: 63px;
	display: block;
	cursor: pointer;
  border-radius: 20px;
	border: 1px solid #0B1010;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

.medicationcolor{
	position: relative;
	text-align: center;
	height: 65px !important;
  width: 65px  !important;
	display: block;
	cursor: pointer;
  border-radius: 50px !important;
  margin-right: 10px;
	border: 1px solid #0B1010;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

.form .plan input:checked + label, .form .payment-plan input:checked + label, .form .payment-type input:checked + label{
	border: 1px solid #0B1010;
  border-radius: 20px;
	background-color: #0B1010;
}





/* Werteabfrage // Multipleselection */

ul.ks-cboxtags {
  padding-left: 0px;
}

ul.ks-cboxtags li {
  display: block;
  text-align: right
}

ul.ks-cboxtags li label {
    display: inline-block;
    border: 1px solid #0B1010;
    color: #0B1010;
    border-radius: 20px;
    white-space: nowrap;
    margin-bottom: 10px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    transition: all .2s;
}

ul.ks-cboxtags li label {
    padding: 8px 15px;
    cursor: pointer;
}

ul.ks-cboxtags li label::before {
    display: inline-block;
    font-weight: normal;
    font-size: 16px;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    transition: transform .3s ease-in-out;
}

ul.ks-cboxtags li input[type="checkbox"]:checked + label {
    border: 1px solid #0B1010;
    background-color: #0B1010;
    color: #fff;
    transition: all .2s;
}

ul.ks-cboxtags li input[type="checkbox"] {
  display: absolute;
}

ul.ks-cboxtags li input[type="checkbox"] {
  position: absolute;
  opacity: 0;
}

/* Links nicht farbig unterstreichen */

a:link {
	text-decoration: none;
}
a:visited {
	text-decoration: none;
}
a:hover {
	text-decoration: none;
}
a:active {
	text-decoration: none;
}


/* Button Popover*/
.btn{
  background: transparent;
  border: 0;
  outline: none;
  border-radius: 30px;
}

.popup{
  background: #0B1010;
  border: 0;
  outline: none;
  border-radius: 30px;
  visibility: hidden;
}

.open-popup{
  visibility: visible;
}

.fixed{
  position: fixed;
  right: 0px;
  left: 0px;
}

/* Button Popover 2*/
.container#blur.active
{
  filter: blur(3px);
  pointer-events: none;
  user-select: none;
}

#popup{
  position: fixed;
  top: 45%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 300px;
  padding: 20px;
  box-shadow: 0 5px 30px rgba(0,0,0,.30);
  background: #fff;
  border-radius: 10px;
  visibility: hidden;
  opacity: 0;
  transition: 0.5s;
}

#popup.active{
  top: 50%;
  visibility: visible;
  opacity: 1;
  transition: 0.5s;
}


/* Auswertung */

.feedback-gut {
  background-color: #4E75FF;
  padding-top: 25px;
  padding-bottom: 25px;
  margin-bottom: 2px;
  display: block;
  border: none;
}

.feedback-schlecht {
  background-color: #FF4E4E;
  padding-top: 25px;
  padding-bottom: 25px;
  margin-bottom: 2px;
  display: block;
  border: none;
}

.todolistueberschrift {
  margin-bottom: 10px;
}

::placeholder {
  opacity: 0.2;
}

.display-inline {
  display: inline;
}

.input-small {
    color: #0B1010;
    text-align: right;
    font-size: 32px;
    font-weight: bold;
    width: 100%;
    border: none;
    height: 450px;
}

.input-small:focus {
outline: none;
}

.input-small-black {
    background-color: transparent;
    color: white;
    text-align: right;
    font-size: 32px;
    font-weight: bold;
    width: 100%;
    border: none;
    height: 450px;
}

.input-small-black:focus {
outline: none;
}

.inputclassic {
  width: 100%;
  height: 60px;
  border: none;
  border-radius: 10px;
  margin-bottom: 15px;
  padding-left: 20px;
}

.inputclassic:focus {
  outline: none;
}


.inputlist {
  width: 70%;
  height: 20px;
  border: none;
  border-radius: 10px;
  padding-left: 20px;
  font-weight: 700;
  font-size: 18px;
  letter-spacing: 0.2px;
  text-align: right;
}

.inputlist:focus {
  outline: none;
}

.delete {
    position: absolute;
    right: 10px;
    background-color: #4E75FF;
    height: 96px;
    width: 96px;
    border-radius: 10px;
    border: none;
    display: inline-block;
    text-align: center;
    margin-top: 1px;
}

.deleteimage {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}




.hometab {
  background-color: #181E22;
  border-radius: 20px;
}

.hometabtoggle {
  padding: 14px 30px 5px 30px;
  border-radius: 20px;
  display: inline-block;
}

.activetoggle {
  background-color: #25323A;
}

.activetoggle h4 {
  opacity: 1!important;
}

.hometabtoggle h4 {
    font-size:12px;
    text-transform: uppercase;
    letter-spacing: 0.7px;
    font-weight: 700;
    color: white;
    opacity: 0.4;
}




  .header {
    overflow: hidden;
    background-color: #0B1010;
    position: fixed;
    top: 0;
    z-index: 10;
    width: 100%;
    padding-top: 70px;
    padding-bottom: 15px;
  }
  .container {
      width: 100%;
      padding-right: 15px;
      padding-left: 15px;
      margin-right: auto;
      margin-left: auto;
  }
  .row {
      display: flex;
      flex-wrap: wrap;
      margin-right: -15px;
      margin-left: -15px;
  }
  .col-right {
      text-align: right;
  }
  .center {
      position: relative;
      margin: auto;
  }
  #triggerpopup-profil {
      background-color: transparent;
      border: none;
      cursor: pointer;
      transition: .2s ease-in-out;
  }
  #triggerpopup-chat {
      background-color: transparent;
      border: none;
      cursor: pointer;
      transition: .2s ease-in-out;
  }
  img {
      vertical-align: middle;
      border-style: none;
  }
  .col-center-content {
      display: flex;
      justify-content: center;
  }
  .col-right {
      text-align: right;
  }
  .center {
      position: relative;
      margin: auto;
  }
  ul {
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      padding-inline-start: 40px;
  }
  ul.tabs {
      display: block;
      width: 200px;
      padding: 0 2px;
      margin: 0;
      list-style: none;
      position: relative;
      border-radius: 20px;
      overflow: hidden;
      height: 36px;
      background-color: #181E22;
      user-select: none;
  }
  .hover {
        width: 100.148px;
      left: 2px;
      content: '';
      background-color: #25323A;
      height: 36px;
      position: absolute;
      -webkit-transition: all 500ms ease-in-out;
      transition: all 500ms ease-in-out;
      border-radius: 35px;
  }
  .tab {
      font-size: 12px;
      height: 36px;
      line-height: 0.7;
      font-weight: bold;
      color: #fff;
      opacity: 0.5;
      text-transform: uppercase;
      letter-spacing: 0.7px;
      text-align: center;
      padding: 14px 29px;
      position: relative;
      z-index: 2;
      cursor: pointer;
      display: inline-block;
      -webkit-transition: color 500ms ease-in-out;
      transition: color 500ms ease-in-out;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
  }
  /* Popup */
  .popup-background {
    display: none;
    background-color: rgba(0,0,0,0.4);
    z-index: 1000;
    position: fixed;
    left:0px;
    top:0px;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(5px);
  }
  .popup-box {
    padding: 20px;
    border-radius: 10px;
    background-color: #ffffff;
    margin: auto;
    width: 95%;
    max-height: 70%;
    margin-top: 70%;
    display: flex;
    flex-direction: column;
  }
  .popup-box-list {
    border-radius: 10px;
    background-color: transparent;
    margin: auto;
    width: 100%;
    max-height: 70%;
    margin-bottom: 9%;
    bottom: 0;
    display: flex;
    flex-direction: column;
    position: absolute;
  }
  .popup-close {
    padding-bottom: 2px;
    padding-top: 2px;
    text-align: right;
    background: none;
    border: none;
  }
  .popup-text {
    margin-bottom: 20px;
  }
  #triggerpopup-chat {
    background-color: transparent;
    border: none;
    cursor: pointer;
    transition: .2s ease-in-out;
  }
  #triggerpopup-profil {
    background-color: transparent;
    border: none;
    cursor: pointer;
    transition: .2s ease-in-out;
  }
  .todolist {
    background-color: #181E22;
    width: 100vw;
  }
  .todolistabschnitt {
    padding-top: 20px;
    padding-left: 0;
  }
  .todolistueberschrift {
    margin-bottom: 10px;
  }
</style>