<template>
  <div class="backgroundlinear">
    
    <div class="headertransparent">
      <div class="container col-center">
        <!--<p class="psmallbold fontcolor-white">KRETA</p>-->
      </div>
    </div>

    <div class="paddingtop20">
      <img src="../assets/icons/welcome.svg" class="welcomeimage">
    </div>


    <div class="welcome">
      <div class="container">
        <div class="margintop30">
          <h2 class="h2 fontcolor-white">Self-Care Unterstützung für Nierentransplantierte</h2>
          <!--Unterstützes Selbstmanagement nach einer NTX-->
        </div>
        <div class="margintop50">
          <button type="button" class="buttonhoch backgroundcolor-lightblue" @click="welcomezulogin()">
            <p class="psmallbold fontcolor-white">Login</p>
          </button>
        </div>
        <div>
          <button type="button" class="buttonhoch backgroundcolor-transparent" @click="welcomezuregistration()">
            <p class="psmallsemibold fontcolor-lightblue">Jetzt registrieren</p>
          </button>
        </div>
        
      </div>
    </div>
  </div>
</template>

<style scoped>
.backgroundlinear {
  height: 100vh;
  width: 100vw;
  background-image: linear-gradient(#0B1010, #25323A);
}

.welcomeimage {
  margin-top: 26vh;
  opacity: 0.6;
}
</style>

<script>
  import { mapActions } from 'vuex';
  import router from '../router';
  import FootNavigationVue from "../components/FootNavigation.vue";

  export default {
    name: 'information',
    components: {
      'foot-navigation' : FootNavigationVue,
    },
    methods: {
      ...mapActions(['loadUserData']),
      welcomezuregistration() {
        router.push({ path: '/registrationusername' })
      },
      welcomezulogin() {
        router.push({path: '/login'})
      }
    },
  };
</script>
