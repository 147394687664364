<template>
  <transition name="modal-fade">
    <div class="modal-backdrop center">
      
      <div class="sfkt">
        <button type="button" class="buttonAutoWidth backgroundcolor-white" @click="schnellfunktionzuwerteabfrage()">
          <p class="psemibold display-inline fontcolor-mainblue">Vitalwerte eintragen</p>
        </button>
        <button disabled type="button" class="buttonAutoWidth backgroundcolor-tertiaryblue opacity80">
          <p class="psemibold display-inline fontcolor-white opacity20">Laborwerte eintragen</p>
        </button>
        <button disabled type="button" class="buttonAutoWidth backgroundcolor-tertiaryblue opacity80">
          <p class="psemibold display-inline fontcolor-white opacity20">Medikationsplan ändern</p>
        </button>

          <button type="button" class="margintop20 centerh buttonschnellfunktion" @click="close">
            <img class="gradfuenfundvierzig" src="../assets/icons/add_dark.svg">
          </button>

      </div>
    </div>
  </transition>
</template>


<!--____________________________________________________________________________________________-->

<style>

  .centerh {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 60px;
  }
  .gradfuenfundvierzig {
    transform: rotate(45deg);
    transition: opacity .5s ease;
  }

  .sfkt {
    overflow-x: auto;
    display: block;
    flex-direction: column;
    max-height: 70%;
    bottom: 30px;
    position: fixed;
    text-align: center;
    margin-left: auto;
    margin-right: auto;

  }

  .modal-header {
    padding: 15px 0px 5px 0px;
    display: flex;
    position: relative;
    justify-content: space-between;
    border-bottom: none;
  }


  .modal-body {
    position: relative;
    padding: 0px 15px 0px 0px;
    text-align: left;
  }

  .modal-fade-enter,
  .modal-fade-leave-to {
    opacity: 0%;
  }

  .modal-fade-enter-active,
  .modal-fade-leave-active {
    transition: opacity .1s ease;
  }


 .buttonschnellfunktion {
   width: 60px;
   height: 45px;
   background-color: white;
   border-radius: 20px;
   display: block;
   display: block;
    margin-left: auto;
    margin-right: auto;
 }
  
</style>


<!--____________________________________________________________________________________________-->

<script>
  import router from '../router';

  export default {
    name: 'SchnellFunktion',
    props: ['modaltitle', 'modaltext',],
    methods: {
      close() {
        this.$emit('close');
      },
      schnellfunktionzuwerteabfrage() {
        router.push({path: '/werteabfragetemperatur'})
      },
    },
  };
</script>