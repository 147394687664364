<template>
  <div>
    <SchnellFunktion
        v-show="isSchnellFunktionVisible"
        @close="closeSchnellFunktion"
        modaltitle="Profil Funktion"
        modaltext=" Mit dieser Funktion können Sie sich in Zukunft schnell und unkompliziert mit Ihrem Versorgungsteam abstimmen und Informationen auszutauschen."
      />
    <nav>
      <div class="container">
        <div class="row">
          <router-link tag="li" class="col" class-active="active" to="/home" exact>
            <img class="centervertically" src="../assets/icons/navi_home_active.svg">
          </router-link>
          <router-link tag="li" class="col" class-active="active" to="/therapieplan" exact>
            <img class="centervertically" src="../assets/icons/navi_therapieplan_active.svg">
          </router-link>
          <div class="col">
            <button type="button" class="buttonschnellfunktion" @click="showSchnellFunktion">
              <img src="../assets/icons/add_dark.svg">
            </button>
          </div>
          
          <router-link tag="li" class="col" class-active="active" to="/werteverlauf" exact>
            <img class="centervertically" src="../assets/icons/navi_werteverlauf_active.svg">
          </router-link>
          <router-link tag="li" class="col" class-active="active" to="/information" exact>
            <img class="centervertically" src="../assets/icons/navi_information_active.svg">
          </router-link>
        </div>
      </div>
    </nav>
  </div>
</template>

<style>

nav {
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 95px;
  background-color: rgba(11, 16, 16, .95);
  box-shadow: 0px -2px 14px rgba(0, 0, 0, 0.35);
  bottom: 0;
  padding-top: 15px;
  z-index: 11;
  backdrop-filter: blur(5px);
}

 nav li {
   opacity: 0.4;
   border: none;
 }

 nav li.router-link-exact-active {
   opacity: 1;
   cursor: pointer;
 }

 .centervertically {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
 }

 .buttonschnellfunktion {
   width: 60px !important;
   height: 45px;
   background-color: white;
   border-radius: 20px;
   display: block;
    margin-left: auto;
    margin-right: auto;
 }
</style>

<script>
  import SchnellFunktion from "../components/SchnellFunktion.vue";

  export default {
    components: {
      SchnellFunktion,
    },
    data() {
      return {
        isSchnellFunktionVisible: false,
      };
    },
    methods: {
      showSchnellFunktion() {
        this.isSchnellFunktionVisible = true;
      },
      closeSchnellFunktion() {
        this.isSchnellFunktionVisible = false;
      },
    },
  };
</script>