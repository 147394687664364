import Vue from 'vue'
import Vuex from 'vuex'

import { getDatabase, ref, set, child, get } from "firebase/database";
import { initializeApp } from "firebase/app";
import { async } from '@firebase/util';

Vue.use(Vuex)

// Your web app's Firebase configuration

// DB Manuel
/*const firebaseConfig = {
  apiKey: "AIzaSyBOahUBME1lmmiqfEs-luQT9mzyDDFC7qU",
  authDomain: "component-app.firebaseapp.com",
  databaseURL: "https://component-app-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "component-app",
  storageBucket: "component-app.appspot.com",
  messagingSenderId: "492172651874",
  appId: "1:492172651874:web:d994cf434ed91ab26a903b"
};*/

// DB Kreta
const firebaseConfig = {
  apiKey: "AIzaSyAYx8dzYG8gWsAMoQhUZTUbRFlq9ZJhBBA",
  authDomain: "kreta-c91d1.firebaseapp.com",
  databaseURL: "https://kreta-c91d1-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "kreta-c91d1",
  storageBucket: "kreta-c91d1.appspot.com",
  messagingSenderId: "857584725391",
  appId: "1:857584725391:web:a64a2569bd6a35ea632ffa",
  measurementId: "G-B3QFEBRBNJ"
};

export default new Vuex.Store({
  state: {
    debug: true,
    userData: {
      "info": {
        "gewicht": "56",
        "einnahmezeiten": {
          "abend": "18:00",
          "frueh": "07:30",
          "mittag": "12:30",
          "nacht": "22:00"
        },
        "medikation": [
          {
            "anzahl": "1 Stück",
            "darstellung": {
              "kapsel": 1,
              "tablette": 0
            },
            "dosis": "1 mg",
            "einnahmezeiten": {
              "abend": {
                "tag": 20220524,
                "zahl": 2
              },
              "frueh": {
                "tag": 20220524,
                "zahl": 1
              },
              "mittag": {
                "tag": 20220524,
                "zahl": 0
              },
              "nacht": {
                "tag": 20220524,
                "zahl": 1
              }
            },
            "name": "Prograf",
            "rhythmus": {
              "dienstag": 1,
              "donnerstag": 1,
              "freitag": 1,
              "mittwoch": 1,
              "montag": 1,
              "samstag": 1,
              "sonntag": 1
            }
          },
          {
            "anzahl": "1 Stück",
            "darstellung": {
              "kapsel": 0,
              "tablette": 1
            },
            "dosis": "5 mg",
            "einnahmezeiten": {
              "abend": {
                "tag": 20220524,
                "zahl": 0
              },
              "frueh": {
                "tag": 20220524,
                "zahl": 0
              },
              "mittag": {
                "tag": 20220524,
                "zahl": 2
              },
              "nacht": {
                "tag": 20220524,
                "zahl": 0
              }
            },
            "name": "Decortin",
            "rhythmus": {
              "dienstag": 1,
              "donnerstag": 1,
              "freitag": 1,
              "mittwoch": 1,
              "montag": 1,
              "samstag": 1,
              "sonntag": 1
            }
          },
          {
            "anzahl": "2 Stück",
            "darstellung": {
              "kapsel": 0,
              "tablette": 1
            },
            "dosis": "360 mg",
            "einnahmezeiten": {
              "abend": {
                "tag": 220524,
                "zahl": 0
              },
              "frueh": {
                "tag": 220524,
                "zahl": 1
              },
              "mittag": {
                "tag": 220524,
                "zahl": 0
              },
              "nacht": {
                "tag": 220524,
                "zahl": 0
              }
            },
            "name": "Myfortic",
            "rhythmus": {
              "dienstag": 1,
              "donnerstag": 1,
              "freitag": 1,
              "mittwoch": 1,
              "montag": 1,
              "samstag": 1,
              "sonntag": 1
            }
          },
          {
            "anzahl": "1 Stück",
            "darstellung": {
              "kapsel": 0,
              "tablette": 1
            },
            "dosis": "480 mg",
            "einnahmezeiten": {
              "abend": {
                "tag": 220524,
                "zahl": 0
              },
              "frueh": {
                "tag": 220524,
                "zahl": 1
              },
              "mittag": {
                "tag": 220524,
                "zahl": 1
              },
              "nacht": {
                "tag": 220524,
                "zahl": 0
              }
            },
            "name": "Cotrimoxazol AL",
            "rhythmus": {
              "dienstag": 0,
              "donnerstag": 0,
              "freitag": 1,
              "mittwoch": 1,
              "montag": 1,
              "samstag": 0,
              "sonntag": 0
            }
          },
          {
            "anzahl": "1 Stück",
            "darstellung": {
              "kapsel": 1,
              "tablette": 0
            },
            "dosis": "20000 (I.E.)",
            "einnahmezeiten": {
              "abend": {
                "tag": 220522,
                "zahl": 0
              },
              "frueh": {
                "tag": 220529,
                "zahl": 1
              },
              "mittag": {
                "tag": 220524,
                "zahl": 0
              },
              "nacht": {
                "tag": 220524,
                "zahl": 1
              }
            },
            "name": "Dekristol",
            "rhythmus": {
              "dienstag": 0,
              "donnerstag": 0,
              "freitag": 0,
              "mittwoch": 0,
              "montag": 1,
              "samstag": 0,
              "sonntag": 0
            }
          }
        ]
      },
      "tage": {
        "20220519": {
          "bestaetigung": {
            "16529877496": {
              "name": "Prograf",
              "time": "21:15"
            }
          }
        },
        "20220520": {
          "bestaetigung": {
            "16530543273": {
              "name": "Werte Erfassung",
              "time": "15:45"
            }
          }
        },
        "20220521": {
          "bestaetigung": {
            "16531363126": {
              "name": "Decortin",
              "time": "14:31"
            }
          },
          "werteabfrage": {
            "befinden": {
              "ausgezeichnet": 0,
              "gut": 0,
              "schlecht": 0,
              "sehrgut": 1,
              "wenigergut": 0
            },
            "blutdruck": "120/81",
            "gewicht": 55,
            "symptome": {
              "muedigkeit": 1,
              "oedeme": 0,
              "schmerzenimtransplantatbereich": 0,
              "schwaeche": 0,
              "sonstiges": 0,
              "verringerteurinausscheidung": 0
            },
            "temperatur": 37
          }
        },
      }
    },
  },
  actions: {
    async initializeFirebase() {
      console.log("connect to firebase");
      initializeApp(firebaseConfig);
      //const app = initializeApp(firebaseConfig);
      //console.log(app);
    },
    async loadUserData({ commit }, userData){
      const dbRef = ref(getDatabase());
      console.log(dbRef);
      //console.log(userData.user);
      //get(child(dbRef, `users/${userData.user}`)).then((snapshot) => {
      get(child(dbRef, `users/${userData.user}`)).then((snapshot) => {
        if (snapshot.exists()) {
          //console.log(snapshot.val());
          commit('setUserData', snapshot.val());
        } else {
          console.log("No data available");
        }
      }).catch((error) => {
        console.error(error);
      });
    },
    async setEinnahmeTag({commit, state}, data){
      // setzt in medikation / einnehmezeiten / tageszeit den tag

      commit("setEinnahmeTag", data);
      const medIndex = state.userData.info.medikation.findIndex((elem) => elem.name == data.name);
      // index von Medikament muss ich noch finden ...

      const day = new Date();
      const currentDay = day.toLocaleDateString('en-GB').split('/').reverse().join('');

      const db = getDatabase();
      set(ref(db, 'users/username/info/medikation/' + medIndex + '/einnahmezeiten/' + data.tagesZeit + '/tag/'), 
        currentDay,
      );
    },
    async setEinnahme({commit}, medName){
      // setzt wert unter info / tage / datum / ...
      const day = new Date();

      let currentDay = day.toLocaleDateString('en-GB').split('/').reverse().join('');
      let _timestamp = '' + Math.floor(day/100);
      //console.log(_timestamp);
    

      const db = getDatabase();
      set(ref(db, 'users/username/tage/' + currentDay + '/bestaetigung/' + _timestamp + '/'), 
        {
          name: medName,
          time: day.getHours() + ':' + day.getMinutes()   
      });
      
      let data = {};
      data[_timestamp] = {
          name: medName,
          time: day.getHours() + ':' + day.getMinutes()   
      };
      let vdata = {};
      vdata[currentDay] = {
          bestaetigung: data,
      };

      let dataWithDay = {
        day: currentDay,
        timestamp: _timestamp,
        data: vdata
      }

      //console.log(dataWithDay);

      commit("setEinnahme", dataWithDay);
    },
    //async setEinahme
    async writeUserDataToDB({commit}, data){
      // write current state to db
      //console.log(data);
      const db = getDatabase();
      let userId = data;
      let name = "uwe";
      let email = "mail@test.de";
      let imageUrl = "./bild.jpg";
      set(ref(db, 'users/' + userId), {
        username: name,
        email: email,
        profile_picture : imageUrl
      });

      // frage Daten von der firebase ab uns setze sie lokal
      get(child(db, `users/${userData.user}`)).then((snapshot) => {
        if (snapshot.exists()) {
          console.log(snapshot.val());
          commit('setUserData', snapshot.val());
        } else {
          console.log("No data available");
        }
      }).catch((error) => {
        console.error(error);
      });
      
    },
    async setWert({commit}, data){

      const day = new Date();
      const currentDay = day.toLocaleDateString('en-GB').split('/').reverse().join('');
      let _timestamp = '' + Math.floor(day/100) + '_' + data.name;


      const db = getDatabase();
      set(ref(db, 'users/username/tage/' + currentDay + '/werte/' + _timestamp + '/'), data);
      
      let _data = {};
      _data[_timestamp] = data;
      let vdata = {};
      vdata[currentDay] = {
          werte: _data,
      };

      let dataWithDay = {
        day: currentDay,
        timestamp: _timestamp,
        data: vdata
      }

      //console.log(dataWithDay);
      commit('setWert', dataWithDay);
    }
  },
  mutations: {
    setUserData: (state, data) => (state.userData = data),
    setEinnahmeTag: (state, data) => {
      const medIndex = state.userData.info.medikation.findIndex((elem) => elem.name == data.name);
      //console.log(data);
      //console.log("medIndex: " + medIndex);
      //console.log(state.userData.info.medikation[medIndex]);
      if(medIndex >= 0){
        const day = new Date();
        //console.log(state.userData.info.medikation[medIndex].einnahmezeiten);
        //console.log(data.tagesZeit);
        //console.log(state.userData.info.medikation[medIndex].einnahmezeiten[data.tagesZeit]);
        //console.log("tageszeit");
        state.userData.info.medikation[medIndex].einnahmezeiten[data.tagesZeit].tag = day.toLocaleDateString('en-GB').split('/').reverse().join('');
      }
    },
    setEinnahme: (state, inData) => {
      //state.userData.tage = data;
      /*console.log("get Day");
      console.log(inData);
      console.log(inData.day);
      console.log(state.userData.tage[inData.day]);*/

      if(state.userData.tage[inData.day] == undefined){
        //console.log("new Day");
        //console.log(inData);
        // andere Tage werden nicht mehr überschrieben
        state.userData.tage[inData.day] = inData.data[inData.day];
        
      } else {
        console.log("setEinnahme-Daten: " + inData);
        state.userData.tage[inData.day].bestaetigung[inData.timestamp] = inData.data[inData.day].bestaetigung[inData.timestamp];
      }
    },
    setWert: (state, inData) => {
      
      if(state.userData.tage[inData.day] == undefined){
        //console.log("new Day");
        //console.log(inData);
        // andere Tage werden nicht mehr überschrieben
        state.userData.tage[inData.day] = inData.data[inData.day];
        
      } else {
        state.userData.tage[inData.day].werte[inData.timestamp] = inData.data[inData.day].werte[inData.timestamp];
      }
    },
    
  },
  getters: {
    getWerte: state => name => {
      let day = new Date();
      let datum = day.toLocaleDateString('en-GB').split('/').reverse().join('');
      //if(state.tage[datum]){


      let itemsObject = state.userData.tage[datum].werte;

      let timestampsArray = Object.keys(itemsObject);
      timestampsArray.sort();
      timestampsArray.reverse();

      for(let i = 0; i<timestampsArray.length; i++){
        //console.log(itemsObject[timestampsArray[i]].name + " : " + name);
        if(itemsObject[timestampsArray[i]].name == name){
          //console.log("return: " + itemsObject[timestampsArray[i]].wert);
          let data = {
            data: itemsObject[timestampsArray[i]].wert,
            id: timestampsArray[i],
          }
          return data;
        }
      }

      return false;

      //}
    },
    getDebug: state => state.debug,
    getUserData: state => state.userData,
    getEinnahmezeiten: state => state.userData.info.einnahmezeiten,
    getVerlauf: state => key => {
      console.log(state.userData.tage);
      console.log(Object.keys(state.userData.tage));
      /*state.userData.info.medikation.filter((m) => {
        return {tag: 20220502, wert: 36}
      })*/
      let data = [];
      let tage = Object.keys(state.userData.tage);
      for(let j = 0; j <tage.length; j++){
        const tag = state.userData.tage[tage[j]];
        if(tag.werte){
          let timestamps = Object.keys(tag.werte);
          for(let i = 0; i < timestamps.length; i++){
            if(tag.werte[timestamps[i]].name == key){
              console.log({ tag: tage[j], wert: tag.werte[timestamps[i]].wert});
              data.push( { tag: tage[j], wert: {data: tag.werte[timestamps[i]].wert, id:timestamps[i]}});
            }
          }
        }
      }
      return data.reverse();
      /*state.userData.tage.filter((dddd) => {
        if(dddd.werte){
          for(let i = 0; i < dddd.werte.keys.length; i++){
            if(dddd.werte[dddd.werte.keys[0]].name == key){
              console.log({ tag: dddd.keys[0], wert: dddd.werte[dddd.werte.keys[0]].wert});
              return { tag: dddd.keys[0], wert: dddd.werte[dddd.werte.keys[0]].wert};
            }
          }
        }
      })*/
    },
    getMedikation: state => tageszeit => 
      
      state.userData.info.medikation.filter((m) => {
        //console.log(m.einnahmezeiten);

        const day = new Date();
        //console.log("dates ...")
        //console.log(m.einnahmezeiten[tageszeit].tag);
        //console.log(day.toLocaleDateString('en-GB').split('/').reverse().join(''));

        

        //const currentTime = ("00" + day.getHours()).slice(-2) + ':' + ("00" + day.getMinutes()).slice(-2);
        //console.log(currentTime);
        
        //console.log(state.userData.info.einnahmezeiten[tageszeit]);
        const zielEinahmeZeit =  state.userData.info.einnahmezeiten[tageszeit];
        let parts = zielEinahmeZeit.split(":");

        //console.log(parseInt(parts[0]) + " " + (day.getHours()+1));
        //console.log(day.getDay());

        const wochentage = ["sonntag", "montag", "dienstag", "mittwoch", "donnerstag", "freitag", "samstag"];

        // check ob tageszeit schon überschritten ist:
        if(parseInt(parts[0]) >= day.getHours()+1){
          // check ob Wochentag im rhythmus enthalten ist:
          if(m.rhythmus[wochentage[day.getDay()]]){
          // check ob medikament um die Tageszeit genommen werden muss und schon an dem Tag eingenommen wurde
            if (m.einnahmezeiten[tageszeit].zahl >= 1 && m.einnahmezeiten[tageszeit].tag != day.toLocaleDateString('en-GB').split('/').reverse().join('')) {
              return m;
            }
          }
        }
    }),
    getTage: state => state.userData.tage,
    getSollEinnahmen: state => (tag, tageszeit) => {
      let countEinnahmen = 0;
      for(let i = 0; i< state.userData.info.medikation.length; i++){
      //state.userData.info.medikation.filter((m) => {
        //console.log(m.einnahmezeiten);
        let m = state.userData.info.medikation[i];

          if(m.rhythmus[tag]){
          // check ob medikament um die Tageszeit genommen werden muss und schon an dem Tag eingenommen wurde
            if (m.einnahmezeiten[tageszeit].zahl >= 1) {
              //return m;
              countEinnahmen ++;
            }
          }
      }
      return countEinnahmen;
    },
    getEmail: state => state.userData.email,
    getMedikationinfos: state => state.userData.info.medikation,
    getMedikationinfosNo: state => no  => {
      //console.log(state.userData.info.medikation[no]);
      return state.userData.info.medikation[no]
    },
    getGewicht: state => state.userData.info.gewicht,
  },
  modules: {
  }
})
